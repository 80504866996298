
<template>
  <div>

    <div class="block w-full text-right mr-2 mb-8">
    </div>
    <vx-card id="ecommerce-checkout-demo" class="bg-h-primary-light">

    <div class="my-5 bg-h-primary p-5 rounded-lg flex w-full" >

      <b-form @submit.stop.prevent="createAntiSanction()" class="w-full">

        <div class="md:flex justify-between gap-4 mb-10" style="direction: ltr;">
          <div class="w-full md:w-1/2">
          </div>
          <div class="w-full md:w-1/2">
            <span class="block"> عنوان</span>
            <input type="text" class="form-control bg-h-primary-light border-grey border-dashed border-opacity-25 h-12 placeholder-white placeholder-opacity-25 text-right" style="direction: rtl !important; text-align: right !important;" dir="rtl" placeholder="عنوان ضد تحریم" v-model="form.antiSanctionTitle" required>
          </div>

        </div>

        <div  class="mt-3 mb-3 bg-h-primary-light p-5 rounded border-white border-dashed border-opacity-25 border">
        <b-tabs content-class="mt-3" class="mb-3 " >

          <b-tab class="p-5" title="انتخاب پلن" v-if="(this.packages.length > 0)" active>
            <div class="lg:flex md:justify-center md:gap-3 w-full block text-center flex-wrap items-center bg-gray ">

              <div :key="plan" v-for="(plan) in packages">
                  <b-form-radio button button-variant="outline-light"
                    v-model="form.selectedPackageID" :key="plan"
                                @change="setPackageResource(form.selectedPackageID)"
                  :value="plan.id"
                    class="border-opacity-25 w-full mb-2 lg:mb-0">
                    <div class="md:flex md:items-center md:gap-2 px-10 py-2  rounded">
                      {{plan.title}}
                    </div>
                  </b-form-radio>
              </div>
            </div>

            <div v-if="(form.selectedPackageID !== '')" class="mt-3 lg:flex md:justify-center md:gap-3 w-full block text-center flex-wrap items-center bg-gray ">
              <div class="bg-grey-light text-h-primary p-2 rounded-lg rounded-t-sm text-center">
                <span class=" text-sm"> مقدار ترافیک: {{ package_total_traffic }} GB</span>
              </div>
            </div>

          </b-tab>
        </b-tabs>
    </div>

        <b-row v-if="(form.selectedPackageID !== '')" class="block md:flex mt-16 items-center p-5 rounded border-white border-dashed border-opacity-25 border">

        <b-col lg="6" md="6" class="text-center md:text-right flex gap-1">

          <div class="bg-grey-light text-h-primary p-2 text-center">
            <span class=" text-sm"> هزینه ماهانه: {{ package_price }} تومان</span>
          </div>

        </b-col>

        <b-col lg="6" md="6" class="text-center md:text-right flex justify-end gap-1">
          <b-button type="submit" variant="primary" class="block text-base md:text-lg px-5 md:px-10 py-3">ساخت ضد تحریم</b-button>
        </b-col>
    </b-row>

      </b-form>
    </div>

  </vx-card>
</div>
</template>

<script>
const names = require('file-loader!./names.txt')

export default {
  data () {
    return {
      form: {
        antiSanctionTitle: '',
        names: [],
        selectedPackageID:''
      },
      packages: {},
      SelectedPackage: {},
      package_total_traffic:'',
      package_price:0,
      shutdown_price:0
    }
  },
  computed: {
    // monthPrice () {
    //   return parseInt(this.prices) * 24 * 30
    // }
  },
  watch: {
  },
  methods: {
    sweetAlert (txt) {
      if (txt[3] !== 'false') {
        this.$swal({
          title: txt[0],
          text: txt[1],
          confirmButtonText : txt[2]
        }).then(() => {
          this.$router.push(txt[3])
        })
      } else {
        this.$swal({
          title: txt[0],
          text: txt[1],
          confirmButtonText : txt[2]
        })
      }
    },
    onSubmit (event) {
      event.preventDefault()
    },
    getAntiSanctionPackages () {
      this.$vs.loading()
      this.$http
        .get('/anti/sanction/packages')
        .then((res) => {
          this.$vs.loading.close()
          this.packages = res.data.params.data
        })
        .catch((err) => {
          this.$vs.loading.close()
          this.$vs.notify({
            color: 'danger',
            title: err.response.data.message
          })
        })
    },
    setPackageResource (id) {
      if (id !== '') {
        this.SelectedPackage = this.packages.find((car) => car.id === parseInt(id))
        this.package_total_traffic = this.SelectedPackage.total_traffic
        this.package_price = this.SelectedPackage.price
      }
    },
    async createAntiSanction () {
      const insData = {
        title: this.form.antiSanctionTitle,
        package_id: this.form.selectedPackageID
      }
      this.$vs.loading()
      try {
        // eslint-disable-next-line no-unused-vars
        await this.$http.post('/anti/sanction', insData)
        this.sweetAlert(['ضد تحریم جدید ساخته شد', '', 'لیست ضد تحریم های شما', '/anti/sanction'])

      } catch (err) {
        this.$vs.notify({
          color: 'danger',
          title: ' خطا',
          text: err.response.data.message
        })
      }
      this.$vs.loading.close()
    }
  },
  components: {
  },
  mounted () {
  },
  created () {
    this.getAntiSanctionPackages()
    fetch(names)
      .then(response => response.text())
      .then(data => {
        this.names = data.split(',')
        this.form.antiSanctionTitle = this.names[Math.floor(Math.random() * this.names.length)]
      })
  }
}
</script>
<style>
.vs-slider--circle {border-radius: 50% }
[dir=rtl] .text-circle-slider.vs-slider--circle-text{    font-family: sans-serif;
    font-size: 16px;
    direction: ltr !important;
}
.theme-dark .con-vs-slider .vs-slider {
    background: #ffffffa1;
    height: 6px;
    border-radius: 50px;
}
.nav-item a {
  color: #696b6d !important;
}
.swal2-popup {
  width: 35em !important;
}
</style>
